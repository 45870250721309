import EmailField from '@/components/Auth/EmailField'
import PasswordField from '@/components/Auth/PasswordField'
import AuthLayout from '@/components/AuthLayout'
import Button from '@/components/Button'
import { LoadingStatus } from '@/consts/common'
import { getShouldBuySubscription } from '@/helpers/freeTrial'
import { ERROR_MESSAGES } from '@/helpers/validation'
import { selectError } from '@/store/auth/selectors'
import { clearError } from '@/store/auth/slice'
import { login } from '@/store/auth/thunks'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import Grid from '@mui/material/Grid'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import styles from './login.module.css'

const passwordRules = {
  required: { value: true, message: ERROR_MESSAGES.PASSWORD_REQUIRED },
}

interface LoginInputs {
  email: string
  password: string
}

const Login = () => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const [loginStatus, setLoginStatus] = useState<(typeof LoadingStatus)[keyof typeof LoadingStatus]>(LoadingStatus.IDLE)

  const error = useAppSelector(selectError)

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<LoginInputs>({ mode: 'all', defaultValues: { email: '', password: '' } })

  const handleDispatchOnSubmit = async (data: LoginInputs): Promise<void> => {
    try {
      setLoginStatus(LoadingStatus.LOADING)
      const response = await dispatch(login(data)).unwrap()

      if (getShouldBuySubscription(response.subscriptionStatus, response.trialExpiresAt)) {
        void router.push('/settings/plans')
        return
      }

      const isDiscoverySectionEnabled = response.features?.discoverySection ?? false
      void router.push(isDiscoverySectionEnabled ? '/' : '/dashboard')
    } catch (e) {
      setLoginStatus(LoadingStatus.IDLE)
    }
  }

  const onSubmit = (e: React.FormEvent): void => {
    void handleSubmit(handleDispatchOnSubmit)(e)
  }

  const onChange = () => {
    if (error.status === 401) {
      dispatch(clearError())
    }
  }

  const isUnAuthorized = error.status === 401

  let errorMessage = null
  // TODO: refactor this after unification of error handling on BE
  if (isUnAuthorized) {
    errorMessage = ERROR_MESSAGES.UNAUTHORIZED
    if (error.message === 'user not active') {
      errorMessage = ERROR_MESSAGES.NOT_VERIFIED
    }
  }

  return (
    <AuthLayout
      footer={
        <div className={styles.footer}>
          <h4>Don&apos;t have one yet?</h4>
          <Link href={'/registration'}>Create account</Link>
        </div>
      }
      title="Login"
    >
      <form onSubmit={onSubmit}>
        <section>
          <h1 className={styles.title}>Log into your account</h1>
          <Grid container spacing={5} className={styles.grid}>
            <Grid item>
              <EmailField<LoginInputs> control={control} onCustomChange={onChange} customError={isUnAuthorized} />
            </Grid>
            <Grid item>
              <PasswordField
                control={control}
                customError={isUnAuthorized}
                onCustomChange={onChange}
                customHelperText={errorMessage}
                additionalRules={passwordRules}
                placeholder="Password"
                name="password"
              />
              <h4 className={styles.forgotPassword}>
                <Link href="/forgot-password">Forgot password</Link>
              </h4>
            </Grid>
          </Grid>
          <Button
            className={styles.loginButton}
            disabled={isUnAuthorized || !isValid}
            type="submit"
            loading={loginStatus === LoadingStatus.LOADING}
          >
            Log in
          </Button>
        </section>
      </form>
    </AuthLayout>
  )
}

export default Login
